import React from 'react';
import Layout from '../../components/App/Layout';
import Navbar from '../../components/App/Navbar';
import PageBanner from '../../components/Common/PageBanner';
import Footer from '../../components/App/Footer';
import JobContent from '../../components/Jobs/JobContent';
import SEO from '../../components/App/SEO';

const Details = () => {
    return (
        <Layout>
          <SEO
            title="採用情報 - インフラエンジニア"
            desc="リタッチのインフラエンジニアの採用情報です。"
            pathname={'/jobs/developer'}
            type={'Article'}
            shortName="採用情報 - インフラエンジニア"
            createdAt="2021-03-02"
            updatedAt="2021-03-02"
          />
            <Navbar />
            <PageBanner
                pageTitle="インフラエンジニア"
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="インフラエンジニア"
                subDirectories={[{
                  url: '/jobs',
                  name: '採用情報'
                }]}
            />
            <JobContent type={"infrastructure"}/>
            <Footer />
        </Layout>
    );
};

export default Details